@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-main-blue {
  background: rgba(20, 154, 227, 0.8);
}

.top-header-middle-title {
  font-size: 25px;
  font-family: "Muli", sans-serif;
  color: #ffffff;
  font-weight: normal;
  margin-right: 20px;
  position: relative;
  display: inline-block;
  line-height: 54px;
}

.top-right-account-button {
  font-size: 20px;
  font-family: "Muli", sans-serif;
  color: #ffffff;
  font-weight: normal;
  margin-right: 5px;
  position: relative;
  display: inline-block;
  line-height: 54px;
}
